<template>
    <v-row>
        <v-col v-for="(item, index) in business" :key="index" cols="6" lg="3">
            <v-card data-aos="fade-up" :data-aos-delay="item.aosDelay" :to="item.link" max-width="342" rounded class="business-card" :class="index % 2 == 0 ? 'ml-auto' : ''">
                <v-img :src="item.image" :aspect-ratio="342 / 512" class="business-card__image w-100" />
                <div class="business-card__contents">
                    <div class="pa-16px pa-md-24px px-lg-34px py-lg-46px">                        
                        <tit class="white--text">{{item.title}}</tit>
                        <txt class="txt--xl white--text mt-4px">{{item.txt}}</txt>
                    </div>
                </div>
            </v-card>
        </v-col> 
    </v-row>
</template>

<script>
import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    components: {
        Tit,
        Txt,
    },
    data: function(){
        return {
            business : [
                {
                    title : "건축물",
                    txt : "하자관리",
                    image : "/images/main/business-img.jpg",
                    link : "/business/building",
                    aosDelay : 100,
                },
                {
                    title : "재개발·재건축",
                    txt : "자문, 소송, 등기",
                    image : "/images/main/business-img2.jpg",
                    link : "/business/renovation",
                    aosDelay : 200,
                },
                {
                    title : "입주예정자협의회",
                    txt : "컨설팅",
                    image : "/images/main/business-img3.jpg",
                    link : "/business/council",
                    aosDelay : 300,
                },
                {
                    title : "건설 엔지니어링",
                    txt : "서비스",
                    image : "/images/main/business-img4.jpg",
                    link : "/business/engineering",
                    aosDelay : 400,
                },
            ],
        };
    },
}
</script>

<style lang="scss" scoped>
.business-card{
    position: relative;
    overflow: hidden;
    background-color: transparent;
    &__contents{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .7);
    }
    &__image{
        transform: scale(1);
        transition: 0.25s ease-out;
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .business-card:hover{
        .business-card__image{
            transform: scale(1.2);
        }
    }
}
@media (min-width:1200px){
}
</style>